<template>
  <div class="newSeeds">
    <div class="title">
      <div class="title-top">
        <!-- <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="addMessage">新增</el-button> -->
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            
            <el-form-item label="日期选择">
              <!-- <el-select v-model="logisticsForm.region" placeholder="请选择">
                <el-option :label="item.name" :value="item.id" v-for="(item, i) in groupingIndex" :key="i"></el-option>
              </el-select> -->

              <div class="block timeNowDay">
                <span class="demonstration"></span>
                <el-date-picker v-model="timeValue" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00']">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item style="margin-left: 30vw" label="操作用户">
              <el-input v-model="logisticsForm.name" placeholder="请输入操作人员"></el-input>
            </el-form-item>
            <el-form-item style="margin-left:2vh">
              <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- <div class="title-bottom">
        <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small">刷新</el-button>
        </div>
        <div class="title-bottom-right">
          <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
          <el-button icon="el-icon-refresh" size="small" @click="removeForm">重置</el-button>
        </div>
      </div> -->
    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column prop="id" label="ID" width="100" show-overflow-tooltip align="center"> </el-table-column>
      <el-table-column prop="operation" label="操作" show-overflow-tooltip align="center"> </el-table-column>
      <el-table-column prop="ip" label="ip地址" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="method" label="接口" show-overflow-tooltip align="center"></el-table-column>
      <!-- <el-table-column prop="doc" label="作用" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column prop="userName" label="操作人员" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="opTime" label="操作时间" show-overflow-tooltip align="center"></el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[8, 10, 50, 100, 150]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                tableData: [],
                rules: {
                    id: [{
                        required: true,
                        message: 'ID不可为空',
                        trigger: ['blur', 'change']
                    }],
                    operation: {
                        required: true,
                        message: '操作不可为空',
                        trigger: 'change'
                    },
                    ip: {
                        required: true,
                        message: 'IP地址不可为空',
                        trigger: ['blur', 'change']
                    },
                    method: {
                        required: true,
                        message: '接口不可为空',
                        trigger: ['blur', 'change']
                    },
                    doc: {
                        required: true,
                        message: '作用不可为空',
                        trigger: ['blur', 'change']
                    },
                    userName: {
                        required: true,
                        message: '操作人员不可为空',
                        trigger: ['blur', 'change']
                    },
                    createTime: {
                        required: true,
                        message: '操作时间不可为空',
                        trigger: ['blur', 'change']
                    },
                },
                boxPrompt: '',
                nowIndex: 0,
                updateForm: false,
                currentPage4: 1,
                multipleSelection: [],
                value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
                logisticsForm: {
                    name: '',
                },
                value2: '',
                page: 1,
                size: 8,
                listTotal: 0,
                loading: false,
                type: 18,
                searchStatus: false,
                timeValue: '',
            };
        },
        mounted() {
            this.requrstList();
            // this.tengxun();
        },
        methods: {
            //请求列表数据
            requrstList(name, startDate, endDate, method) {
                this.$get('/system/getOpLogList', {
                    page: this.page,
                    size: this.size,
                    type: this.type,
                    name: name || '',
                    startDate: startDate || '',
                    endDate: endDate || '',
                    method: method || ''
                }).then(
                    (res) => {
                        //console.log(res);
                        if (res.data.state == 'success') {
                            this.listTotal = res.data.count;
                            this.tableData = res.data.datas;
                        }
                        setTimeout(() => {
                            this.loading = false;
                        }, 500);
                    }
                );
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach((row) => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            //刷新
            refresh() {
                this.loading = true;
                // this.requrstList();
                if (this.searchStatus) {
                    this.search();
                } else {
                    // this.search();
                    this.requrstList();
                }
            },
            //搜索
            search() {
                let name = this.logisticsForm.name;
                this.page = 1;
                this.loading = true;
                this.searchStatus = true;
                let time1 = '';
                let time2 = '';
                if (name == '' || !name) this.searchStatus = false;
                if (this.timeValue == '' || !this.timeValue) {
                    this.searchStatus = false;
                    time1 = '';
                    time2 = '';
                } else {
                    time1 = this.timeValue[0] || '';
                    time2 = this.timeValue[1] || '';
                }

                this.requrstList(name || '', time1, time2);
            },

            // tengxun() {
            //   //   this.$axios({
            //   //     methods: 'get',
            //   //     url: 'https://wis.qq.com/weather/common',
            //   //     dataType: 'jsonp',
            //   //     params: {
            //   //       source: 'pc',
            //   //       weather_type: 'observe',
            //   //       province: '河南省',
            //   //       city: '周口市',
            //   //     },
            //   //   });
            //   this.$tianqi('', { source: 'pc', weather_type: 'observe', province: '河南省', city: '周口市' });
            // },
            //重置
            removeForm() {
                this.loading = true;
                this.logisticsForm.name = '';
                this.timeValue = '';
                this.requrstList();
            },
            handleEdit() {},
            handleDelete() {},
            handleSizeChange(val) {
                this.size = val;
                  if(this.timeValue && this.timeValue.length!=0){
                    this.requrstList('',this.timeValue[0],this.timeValue[1]);
                }else{
                    this.requrstList();
                }
            },
            handleCurrentChange(val) {
                this.page = val;
                if(this.timeValue && this.timeValue.length!=0){
                    this.requrstList('',this.timeValue[0],this.timeValue[1]);
                }else{
                    this.requrstList();
                }
            },
        },
    };
</script>

<style scoped lang="less">
    @import '@/style/backTable.less';
    .operation {
        display: flex;
    }
    
    .newSeeds /deep/ .el-table td,
    .el-table th {
        padding: 1.11vh 0;
    }
    
    .title {
        height: 6vh;
        overflow: hidden;
    }
    
    .newSeeds {
        max-height: 91vh;
        overflow-y: auto;
    }
</style>